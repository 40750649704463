:root {
  --primary-dark: #181820;
  --primary-light: #fff;
  --primary-gray: #999;
}

html[data-theme="light"] {
  --primary-dark: #fff;
  --primary-light: #181820;
  --primary-gray: #333333;
}

* {
  box-sizing: border-box;
  color: var(--primary-light);
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
}

body {
  background: var(--primary-dark);
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main {
  width: 80vw;
  color: var(--primary-light);
  /* pointer-events: none; */
}

.h1,
.h2,
.h3 {
  max-width: 900px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.15;
  font-weight: 600;
  letter-spacing: -0.04em;
  color: var(--primary-light);
  filter: drop-shadow(1px 0px 10px var(--primary-dark));
}

.h1 {
  font-size: 2rem;
}
@media (min-width: 800px) {
  .h1 {
    font-size: 4rem;
  }
}

.h2 {
  margin-bottom: 0.833rem;
  font-size: 1.6rem;
}
@media (min-width: 800px) {
  .h2 {
    margin-bottom: 1.666rem;
    font-size: 2.333rem;
  }
}

.h3 {
  font-size: 1.1rem;
}
@media (min-width: 800px) {
  .h3 {
    font-size: 1.333rem;
  }
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer {
  /* position: fixed;
  left: 0;
  right: 0;
  bottom: 0; */
  transform: initial;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background: var(--primary-dark);
  z-index: 16678276;
}

@media (min-width: 800px) {
  .footer {
    flex-direction: row;
  }
}

.socials {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 8px 0;
  padding: 0;
}

.socials > li {
  margin: 0 6px;
}

.socials .hidden {
  font-size: 0;
}

.githubIcon .iconGraphic {
  display: block;
  width: 35px;
  height: 34px;
  background: url("./images/github.inline.svg");
}

.twitterIcon .iconGraphic {
  display: block;
  width: 36px;
  height: 29px;
  background: url("./images/twitter.inline.svg");
}

.mediumIcon .iconGraphic {
  display: block;
  width: 32px;
  height: 32px;
  background: url("./images/medium.inline.svg");
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.dark_mode_toggle {
  position: absolute;
  top: 12px;
  right: 12px;
}

.staticBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("./images/background.png");
  background-position: center;
  background-size: cover;
  z-index: -1;
}
